<script>
export default {
  props:{
    props: Object
  }
}
</script>

<template>
  <div class="section-with-accordion-list py-5">
    <div class="row">
      <div class="col-12 text-center">
        <h2 class="font-weight-bold">{{props.title}}</h2>
      </div>
      <div class="col-12 text-center">
        <p class="lead text-muted">{{props.description}}</p>
      </div>
      <div class="col-12">
        <div id="accordion-items" class="accordion my-5">
          <div v-for="(item, index) in props.items"
            v-bind:key="index"
            class="card mb-2 border-0 rounded">
            <div class="card-header border-0 bg-light p-3 pr-5">
              <a href="javascript: void(0);"
                class="faq position-relative text-dark"
                aria-expanded="true"
                v-bind:aria-controls="`accordion-item-${index}`"
                data-toggle="collapse"
                v-bind:data-target="`#accordion-item-${index}`">
                <h6 class="title mb-0">
                  {{item.title}}
                </h6>
              </a>
            </div>
            <div v-bind:id="`accordion-item-${index}`" role="tabpanel" class="collapse">
              <div class="card-body px-2 py-4">
                <p class="text-muted mb-0 faq-ans">
                  {{item.description}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>